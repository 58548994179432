<template>
  <div class="wrapper">
    <StSkeletonLoader
      class="image-skeleton"
      height="auto"
      bg-color="#2D2D39"
      :opacity="0.28"
    />
    <div class="bottom">
      <StSkeletonLoader
        bg-color="#2D2D39"
        height="12px"
        width="120px"
        :opacity="0.28"
      />
      <StSkeletonLoader
        bg-color="#2D2D39"
        height="10px"
        width="70px"
        :opacity="0.28"
      />
    </div>
  </div>
</template>

<style scoped>
.image-skeleton {
  aspect-ratio: 3/2;
}

.bottom {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);
  justify-content: center;

  height: 30px;
  padding-top: var(--spacing-100);
}
</style>
